import type { SelectProps, TextFieldProps } from '@mui/material';
import type { Provider } from '@repo/api-gw-sdk';
import type { ColumnDefBase } from '@tanstack/react-table';
import type React from 'react';

import type { FilterProps } from '@/components/queryBuilder/filters';

export type Condition =
  | NumberCondition
  | StringCondition
  | ListCondition
  | CombineCondition;

export interface CombineCondition {
  id?: string;
  type: 'Combine';
  operator: CombineOperator;
  conditions: Condition[];
}

interface NumberCondition {
  id?: string;
  type: 'Number';
  operator: NumberOperator;
  property: string;
  value: string[];
}

export interface StringCondition {
  id?: string;
  type: 'String';
  operator: StringOperator;
  property: string;
  value: string[];
}

interface ListCondition {
  id?: string;
  type: 'List';
  operator: ListOperator;
  property: string;
  value: string[];
}

export type ValueCondition = Exclude<Condition, CombineCondition>;

export function isValueCondition(
  condition: Condition | undefined
): condition is ValueCondition {
  return !!condition && condition.type !== 'Combine';
}

export function isCombineCondition(
  condition: Condition | undefined
): condition is CombineCondition {
  return condition?.type === 'Combine';
}

export const createCombineCondition = (
  conditions: Condition[] = [],
  operator = CombineOperator.And
) => ({
  type: 'Combine' as const,
  operator,
  conditions,
});

export function collectValueConditions(condition: Condition): ValueCondition[] {
  return isValueCondition(condition)
    ? [condition]
    : condition.conditions.flatMap(collectValueConditions);
}

export enum NumberOperator {
  Equals = '=',
  NotEquals = '<>',
  GreaterThan = '>',
  GreaterThanOrEqual = '>=',
  LessThan = '<',
  LessThanOrEqual = '<=',
}

export enum StringOperator {
  In = 'In',
  NotIn = 'Not In',
  Equals = 'Equals',
  NotEquals = 'Not Equals',
  Contains = 'Contains',
  NotContains = 'Not Contains',
  StartsWith = 'Starts With',
  NotStartsWith = 'Not Starts With',
  EndsWith = 'Ends With',
  NotEndsWith = 'Not Ends With',
  Empty = 'Empty',
  NotEmpty = 'Not Empty',
}

export enum ListOperator {
  In = 'In',
  NotIn = 'Not In',
  Equals = 'Equals',
  NotEquals = 'Not Equals',
  Contains = 'Contains',
  NotContains = 'Not Contains',
  ContainsNoneOf = 'Contains None Of',
  ContainsAnyOf = 'Contains Any Of',
  Empty = 'Empty',
  NotEmpty = 'Not Empty',
}

export enum CombineOperator {
  And = 'And',
  Or = 'Or',
}

export enum PropertyType {
  Number = 'Number',
  Date = 'Date',
  String = 'String',
  SingleValue = 'SingleValue',
  MultipleValues = 'MultipleValues',
}

export enum PropertyPolicyRelation {
  SingleValue = 'SingleValue',
  MultipleValues = 'MultipleValues',
  String = 'String',
  Tags = 'Tags',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FilterProperty<T = any> {
  name: string;
  displayName: string;
  type: PropertyType;
  policyRelation?: PropertyPolicyRelation;
  propertyPredicate?: (providers: Set<Provider>) => boolean;
  group: string;
  linkedProperties?: FilterProperty[];
  column?: ColumnDefBase<T>;
  field?: {
    titleKey?: (entity: T) => string;
    value: (entity: T) => React.ReactNode;
  };
  filter?: {
    openFullSize?: {
      title: string;
      subtitle?: string;
    };
    Component?: (props: FilterProps) => React.ReactNode;
    Badge?: ({ conditionValue }: { conditionValue: string }) => React.ReactNode;
    shouldHideBadge?: (condition: ValueCondition) => boolean;
  };
  dropdown?: {
    Component: (props: SelectProps) => React.ReactNode;
  };
  textInput?: {
    Component: (props: TextFieldProps) => React.ReactNode;
  };
}
