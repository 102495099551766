import type {
  Configuration,
  RestoreFilesRequest,
  RestoreDbToRdsInstanceRequest,
  RestoreBucketRequest,
  RestoreAtlasClusterInput,
  RestoreInstanceInput,
  ListAzureStorageAccountContainers200Response,
  RestoreImageInput,
  RestoreAzureVMInput,
  RestoreAzureDiskInput,
  RestoreVolumeToEbsRequest,
  ListAzureSKUs200Response,
  ResourceType,
  RestoreEksNamespaceInput,
} from '@repo/api-gw-sdk';
import {
  SnapshotsApi,
  ListS3BucketsApi,
  ListAvailabilityZonesApi,
  ListEncryptionKeysApi,
  ListRestoreRegionsApi,
  ListAwsSecurityGroupsApi,
  ListAwsRdsSubnetGroupsApi,
  ListAtlasProjectsApi,
  RestoreAtlasClusterApi,
  RestoreInstanceApi,
  ListAwsInstanceTypesApi,
  ListAwsInstanceProfilesApi,
  ListAwsSubnetsV2Api,
  ListAzureStorageAccountsApi,
  ListAzureStorageAccountContainersApi,
  RestoreImageApi,
  ListAzureResourceGroupsApi,
  RestoreAzureVmApi,
  RestoreAzureDiskApi,
  ListAzureUnassignedNetworkInterfacesApi,
  ListAzureSKUsApi,
  RestoreEksNamespaceApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/restore';

export const restore = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const listS3BucketsApi = new ListS3BucketsApi(config);
  const snapshotsApi = new SnapshotsApi(config);
  const listEncryptionKeysApi = new ListEncryptionKeysApi(config);
  const listAwsSecurityGroupsApi = new ListAwsSecurityGroupsApi(config);
  const listRestoreRegionsApi = new ListRestoreRegionsApi(config);
  const listAvailabilityZonesApi = new ListAvailabilityZonesApi(config);
  const listAtlasProjectsApi = new ListAtlasProjectsApi(config);
  const restoreAtlasClusterApi = new RestoreAtlasClusterApi(config);
  const listAwsRdsSubnetGroupsApi = new ListAwsRdsSubnetGroupsApi(config);
  const restoreInstanceApi = new RestoreInstanceApi(config);
  const restoreImageApi = new RestoreImageApi(config);
  const restoreAzureVmApi = new RestoreAzureVmApi(config);
  const restoreAzureDiskApi = new RestoreAzureDiskApi(config);
  const listAwsInstanceTypesApi = new ListAwsInstanceTypesApi(config);
  const listAwsInstanceProfilesApi = new ListAwsInstanceProfilesApi(config);
  const listAwsSubnetsV2Api = new ListAwsSubnetsV2Api(config);
  const listAzureResourceGroupsApi = new ListAzureResourceGroupsApi(config);
  const listAzureNetworkInterfacesApi =
    new ListAzureUnassignedNetworkInterfacesApi(config);
  const listAzureStorageAccountsApi = new ListAzureStorageAccountsApi(config);
  const listAzureStorageAccountContainersApi =
    new ListAzureStorageAccountContainersApi(config);
  const listAzureSKUSApi = new ListAzureSKUsApi(config);
  const restoreEksNamespaceApi = new RestoreEksNamespaceApi(config);

  return {
    volume: (
      resourceId: string,
      snapshotId: string,
      payload: RestoreVolumeToEbsRequest
    ) =>
      snapshotsApi.restoreEbsVolume(
        currentProjectId,
        resourceId,
        snapshotId,
        payload
      ),
    files: (
      resourceId: string,
      snapshotId: string,
      restoreFilesInput: RestoreFilesRequest
    ) =>
      snapshotsApi.restoreFiles(
        currentProjectId,
        resourceId,
        snapshotId,
        restoreFilesInput
      ),
    database: (
      id: string,
      snapshotId: string,
      restoreDatabaseInput: RestoreDbToRdsInstanceRequest
    ) =>
      snapshotsApi.restoreDatabase(
        currentProjectId,
        id,
        snapshotId,
        restoreDatabaseInput
      ),
    encryptionKeys: {
      list: (cloudAccountId: string, regionName: string) =>
        httpClient.execute(
          [
            baseUrl,
            cloudAccountId,
            '/encryptionKeys',
            regionName,
            currentProjectId,
          ],
          () =>
            listEncryptionKeysApi.listEncryptionKeys(
              cloudAccountId,
              regionName,
              currentProjectId
            )
        ),
    },

    regions: {
      list: (cloudAccountId: string) =>
        httpClient.execute(
          [baseUrl, cloudAccountId, '/regions', currentProjectId],
          () =>
            listRestoreRegionsApi.listRestoreRegions(
              cloudAccountId,
              currentProjectId
            )
        ),
      listMany: (cloudAccountIds: string[]) =>
        httpClient.execute(
          [baseUrl, cloudAccountIds, '/regions', currentProjectId],
          () =>
            Promise.all(
              cloudAccountIds.map((cloudAccountId) =>
                listRestoreRegionsApi.listRestoreRegions(
                  cloudAccountId,
                  currentProjectId
                )
              )
            ).then((results) => {
              const regions = new Set<string>();
              results.forEach((result) =>
                result.regions.forEach((region) => regions.add(region))
              );

              return {
                regions: Array.from(regions),
              };
            })
        ),
    },
    availabilityZones: {
      list: (cloudAccountId: string, region?: string) =>
        httpClient.execute(
          [
            baseUrl,
            cloudAccountId,
            '/regions',
            region,
            '/availabilityZones',
            currentProjectId,
          ],
          () => {
            if (!region) {
              return Promise.resolve(undefined);
            }

            return listAvailabilityZonesApi.listAvailabilityZones(
              cloudAccountId,
              region,
              currentProjectId
            );
          }
        ),
    },
    s3Buckets: {
      list: (accountId: string) =>
        httpClient.execute(
          [baseUrl, '/buckets', currentProjectId, accountId],
          accountId
            ? () => listS3BucketsApi.listS3Buckets(accountId, currentProjectId)
            : () => Promise.resolve(undefined)
        ),
      listPromise: (accountId: string) =>
        listS3BucketsApi.listS3Buckets(accountId, currentProjectId),
      restore: (
        resourceId: string,
        snapshotId: string,
        restoreBucketInput: RestoreBucketRequest
      ) =>
        snapshotsApi.restoreBucket(
          currentProjectId,
          resourceId,
          snapshotId,
          restoreBucketInput
        ),
    },
    azureRestoreConfig: {
      listResourceGroups: (accountId: string) =>
        httpClient.execute(
          [baseUrl, '/storageAccounts', currentProjectId],
          () =>
            listAzureResourceGroupsApi.listAzureResourceGroups(
              accountId,
              currentProjectId
            )
        ),
      listNetworkInterfaces: (accountId: string, resourceGroup: string) =>
        httpClient.execute(
          [baseUrl, '/networkInterfaces', currentProjectId, resourceGroup],
          () =>
            listAzureNetworkInterfacesApi.listAzureUnassignedNetworkInterfaces(
              accountId,
              currentProjectId,
              { resourceGroup: resourceGroup }
            )
        ),
      listSKUs: (
        accountId: string,
        location: string,
        resourceType: ResourceType.AzureVirtualMachine | ResourceType.AzureDisk
      ) =>
        httpClient.execute(
          [baseUrl, '/SKUS', currentProjectId, location, resourceType],
          () => {
            if (!location) {
              return Promise.resolve({ skus: [] } as ListAzureSKUs200Response);
            }
            return listAzureSKUSApi.listAzureSKUs(accountId, currentProjectId, {
              location,
              resourceType,
            });
          }
        ),
    },
    storageAccounts: {
      list: (accountId: string) =>
        httpClient.execute(
          [baseUrl, '/storageAccounts', currentProjectId],
          () =>
            listAzureStorageAccountsApi.listAzureStorageAccounts(
              accountId,
              currentProjectId
            )
        ),
      listContainers: (
        accountId: string,
        storageAccountName: string,
        resourceGroup: string
      ) =>
        httpClient.execute(
          [
            baseUrl,
            '/storageAccounts',
            accountId,
            storageAccountName,
            '/containers',
            currentProjectId,
          ],
          () => {
            if (!storageAccountName || !resourceGroup) {
              return Promise.resolve({
                containers: [],
              } as ListAzureStorageAccountContainers200Response);
            }
            return listAzureStorageAccountContainersApi.listAzureStorageAccountContainers(
              accountId,
              currentProjectId,
              {
                storageAccount: {
                  name: storageAccountName,
                  resourceGroup: resourceGroup,
                  location: '',
                },
              }
            );
          }
        ),
    },
    securityGroups: {
      list: (
        cloudAccountId: string,
        region: string,
        vpc?: string,
        subnetId?: string
      ) =>
        httpClient.execute(
          [
            baseUrl,
            cloudAccountId,
            '/regions',
            region,
            '/aws-security-groups',
            currentProjectId,
            vpc,
            subnetId,
          ],
          () =>
            listAwsSecurityGroupsApi.listAwsSecurityGroups(
              cloudAccountId,
              region,
              currentProjectId,
              vpc,
              subnetId
            )
        ),
    },
    rdsSubnetGroup: {
      list: (cloudAccountId: string, region: string) =>
        httpClient.execute(
          [
            baseUrl,
            cloudAccountId,
            '/regions',
            region,
            '/aws-rds-subnet-groups',
            currentProjectId,
          ],
          () => {
            if (!region) {
              return Promise.resolve(undefined);
            }

            return listAwsRdsSubnetGroupsApi.listAwsRdsSubnetGroups(
              cloudAccountId,
              region,
              currentProjectId
            );
          }
        ),
    },
    atlasProjects: {
      list: (accountId: string) =>
        httpClient.execute([baseUrl, '/atlasProjects', currentProjectId], () =>
          listAtlasProjectsApi.listAtlasProjects(accountId, currentProjectId)
        ),
      restore: (
        resourceId: string,
        snapshotId: string,
        restoreAtlasClusterInput: RestoreAtlasClusterInput
      ) =>
        restoreAtlasClusterApi.restoreAtlasCluster(
          currentProjectId,
          resourceId,
          snapshotId,
          restoreAtlasClusterInput
        ),
    },
    instanceTypes: {
      list: (cloudAccountId: string, region: string, subnetId: string) =>
        httpClient.execute(
          [
            baseUrl,
            '/listAwsInstanceTypes',
            currentProjectId,
            cloudAccountId,
            region,
            subnetId,
          ],
          () =>
            listAwsInstanceTypesApi.listAwsInstanceTypes(
              cloudAccountId,
              region,
              currentProjectId,
              subnetId
            )
        ),
    },
    instanceProfiles: {
      list: (cloudAccountId: string, region: string) =>
        httpClient.execute(
          [
            baseUrl,
            '/listAwsInstanceProfiles',
            currentProjectId,
            cloudAccountId,
            region,
          ],
          () =>
            listAwsInstanceProfilesApi.listAwsInstanceProfiles(
              cloudAccountId,
              region,
              currentProjectId
            )
        ),
    },
    awsSubnets: {
      list: (cloudAccountId: string, region: string) =>
        httpClient.execute(
          [
            baseUrl,
            '/listAwsSubnets',
            currentProjectId,
            cloudAccountId,
            region,
          ],
          () =>
            listAwsSubnetsV2Api.listAwsSubnetsV2(
              cloudAccountId,
              region,
              currentProjectId
            )
        ),
    },
    ec2: {
      restore: (
        resourceId: string,
        snapshotId: string,
        payload: RestoreInstanceInput
      ) =>
        restoreInstanceApi.restoreInstance(
          currentProjectId,
          resourceId,
          snapshotId,
          payload
        ),
      restoreImage: (
        resourceId: string,
        snapshotId: string,
        payload: RestoreImageInput
      ) =>
        restoreImageApi.restoreImage(
          currentProjectId,
          resourceId,
          snapshotId,
          payload
        ),
    },
    azureVm: {
      restore: (
        resourceId: string,
        snapshotId: string,
        payload: RestoreAzureVMInput
      ) =>
        restoreAzureVmApi.restoreAzureVm(
          currentProjectId,
          resourceId,
          snapshotId,
          payload
        ),
      restoreDisk: (
        resourceId: string,
        snapshotId: string,
        payload: RestoreAzureDiskInput
      ) =>
        restoreAzureDiskApi.restoreAzureDisk(
          currentProjectId,
          resourceId,
          snapshotId,
          payload
        ),
    },
    eks: {
      restoreNamespace: (
        resourceId: string,
        snapshotId: string,
        payload: RestoreEksNamespaceInput
      ) =>
        restoreEksNamespaceApi.restoreEksNamespace(
          currentProjectId,
          resourceId,
          snapshotId,
          payload
        ),
    },
  };
};
