// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import format from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(calendar);
dayjs.extend(format);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

export const DATE_FORMAT = 'll'; // MMM D, YYYY
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export { dayjs };

export default dayjs;
