import type { Configuration } from '@repo/api-gw-sdk';
import { SecurityScansApi } from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

const baseUrl = '/security';

export const security = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const securityScansApi = new SecurityScansApi(config);

  return {
    getScansSummary: (resourceId: string) =>
      httpClient.execute(
        [baseUrl, 'getScansSummary', resourceId, currentProjectId],
        () =>
          securityScansApi.getSecurityScansSummary(resourceId, currentProjectId)
      ),
    countInfectedResources: () =>
      httpClient.execute([baseUrl, 'count', currentProjectId], () =>
        securityScansApi.countInfectedResources(currentProjectId)
      ),
    alerts: {
      mute: (resourceId: string) =>
        securityScansApi.saveSecurityProperties(resourceId, currentProjectId, {
          securityProperties: { muteAlerts: true, resourceId },
        }),
      unmute: (resourceId: string) =>
        securityScansApi.saveSecurityProperties(resourceId, currentProjectId, {
          securityProperties: { muteAlerts: false, resourceId },
        }),
    },
    getProperties: (resourceId: string) =>
      httpClient.execute(
        [baseUrl, 'properties', resourceId, currentProjectId],
        () =>
          securityScansApi.getSecurityProperties(resourceId, currentProjectId)
      ),
  };
};
