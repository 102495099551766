import type { Configuration, S3ExclusionRule } from '@repo/api-gw-sdk';
import {
  ListS3ExclusionRulesApi,
  CreateS3ExclusionRuleApi,
  UpdateS3ExclusionRuleApi,
  DeleteS3ExclusionRuleApi,
} from '@repo/api-gw-sdk';
import type { ColumnSort, PaginationState } from '@tanstack/react-table';

import type { HttpClient } from './httpClient';

const baseUrl = '/3-exclusion-rules';

export const s3ExclusionRules = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const listS3ExclusionRulesApi = new ListS3ExclusionRulesApi(config);
  const createS3ExclusionRuleApi = new CreateS3ExclusionRuleApi(config);
  const updateS3ExclusionRuleApi = new UpdateS3ExclusionRuleApi(config);
  const deleteS3ExclusionRuleApi = new DeleteS3ExclusionRuleApi(config);

  return {
    list: (pagination?: PaginationState, sorting?: ColumnSort) =>
      httpClient.execute([baseUrl, pagination, sorting, currentProjectId], () =>
        listS3ExclusionRulesApi.listS3ExclusionRules(
          currentProjectId,
          pagination?.pageIndex,
          pagination?.pageSize,
          sorting && `${sorting.id}:${sorting.desc ? 'desc' : 'asc'}`
        )
      ),
    create: (rule: S3ExclusionRule) =>
      createS3ExclusionRuleApi.createS3ExclusionRule(currentProjectId, rule),
    update: (rule: S3ExclusionRule) =>
      updateS3ExclusionRuleApi.updateS3ExclusionRule(
        rule.id,
        currentProjectId,
        rule
      ),
    delete: (ruleId: string) =>
      deleteS3ExclusionRuleApi.deleteS3ExclusionRule(ruleId, currentProjectId),
  };
};
