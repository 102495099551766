import {
  AzureManagementGroup,
  AzureSubscription,
  type AzureOnboardingRequest,
  type Configuration,
} from '@repo/api-gw-sdk';
import {
  AzureOnboardingRequestAccountTypeEnum,
  GetAzureAccountConnectIdApi,
  GetAzureConsentUrlApi,
  GetAzureOnboardingStatusApi,
  GetAzureSubscriptionsApi,
  OnboardAzureSubscriptionsApi,
} from '@repo/api-gw-sdk';

import type { HttpClient } from './httpClient';

export const azure = (
  httpClient: HttpClient,
  config: Configuration,
  currentProjectId: string
) => {
  const consentUrlApi = new GetAzureConsentUrlApi(config);
  const listSubscriptionsApi = new GetAzureSubscriptionsApi(config);
  const onboardSubscriptionsApi = new OnboardAzureSubscriptionsApi(config);
  const onboardingStatusApi = new GetAzureOnboardingStatusApi(config);
  const connectAzureAccountApi = new GetAzureAccountConnectIdApi(config);
  return {
    getConsentUrl: (requestId: string, tenantId?: string) =>
      consentUrlApi.getAzureConsentUrl(currentProjectId, requestId, tenantId),
    listSubscriptions: (requestId: string) =>
      listSubscriptionsApi.getAzureSubscriptions(currentProjectId, requestId),
    onboardSubscriptions: (
      requestId: string,
      accountType: string,
      tenantId: string,
      // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
      selectedEntity: AzureManagementGroup | AzureSubscription
    ) => {
      let onboardingAccountType: AzureOnboardingRequestAccountTypeEnum;
      if (accountType === 'source') {
        onboardingAccountType = AzureOnboardingRequestAccountTypeEnum.Source;
      } else if (accountType === 'restore') {
        onboardingAccountType = AzureOnboardingRequestAccountTypeEnum.Restore;
      } else {
        throw new Error(`Invalid account type: ${accountType}`);
      }

      const subscriptions: AzureSubscription[] = [];
      const managementGroups: AzureManagementGroup[] = [];
      if (selectedEntity instanceof AzureSubscription) {
        subscriptions.push(selectedEntity);
      }

      if (selectedEntity instanceof AzureManagementGroup) {
        managementGroups.push(selectedEntity);
      }

      return onboardSubscriptionsApi.onboardAzureSubscriptions(
        currentProjectId,
        requestId,
        {
          accountType: onboardingAccountType,
          tenantId: tenantId,
          subscriptions,
          managementGroups,
        } as AzureOnboardingRequest
      );
    },
    getOnboardingStatus: (requestId: string) =>
      onboardingStatusApi.getAzureOnboardingStatus(currentProjectId, requestId),
    getAccountConnectId: () => {
      return httpClient.execute(['getAccountConnectId', currentProjectId], () =>
        connectAzureAccountApi.getAzureAccountConnectId(currentProjectId)
      );
    },
  };
};
